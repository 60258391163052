import "@hotwired/turbo-rails";

import "./pwa_install";
import "./custom_chart";
import "./controllers";

import { RRule } from "rrule";
window.RRule = RRule;

import Tagify from "@yaireo/tagify";
window.Tagify = Tagify;

import "chartkick/chart.js";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { SankeyController, Flow } from "chartjs-chart-sankey";
import {
  SortedStack,
  CustomTooltip,
  PaddingBelowLegends,
  DarkModeLabelPlugin,
  BeautifyPlugin,
} from "./custom_chart";

Chart.register(
  BeautifyPlugin,
  SortedStack,
  CustomTooltip,
  PaddingBelowLegends,
  DarkModeLabelPlugin,
  SankeyController,
  Flow,
  ChartDataLabels
);

window.Chart = Chart;
window.ChartDataLabels = ChartDataLabels;
window.DarkModeLabelPlugin = DarkModeLabelPlugin;

// hide labels when value is 0
Chart.defaults.set("plugins.datalabels", {
  display: function (context) {
    return context.dataset.data[context.dataIndex] !== 0;
  },
});

import AOS from "aos";
window.AOS = AOS;
AOS.init();

import Alpine from "alpinejs";

// Alpine Persist Plugin with URL Path Namespacing
document.addEventListener("alpine:init", () => {
  Alpine.directive("persist", (el, { expression }, { effect }) => {
    const component = window.Alpine.$data(el);
    const fieldName = expression.trim();

    const path = window.location.pathname.replace(/^\/|\/$/g, "");
    const sessionKey = `alpine-persist-${fieldName}-${path}`;

    // Load initial value from sessionStorage if it exists
    const storedValue = sessionStorage.getItem(sessionKey);
    if (storedValue !== null) {
      component.$data[fieldName] = JSON.parse(storedValue);
    }

    // Watch for changes and persist to sessionStorage
    effect(() => {
      const value = component.$data[fieldName];
      sessionStorage.setItem(sessionKey, JSON.stringify(value));
    });

    // Clear the sessionStorage when the form is submitted
    const form = el.tagName === 'FORM' ? el : el.querySelector('form')
    if (form) {
      form.addEventListener('submit', () => {
        console.log('submitting form')
        // Small delay to allow any final x-model bindings to complete
        setTimeout(() => {
          sessionStorage.removeItem(sessionKey)
        }, 0)
      })
    }
  });
});

window.Alpine = Alpine;
var alpineInitialized = false;
document.addEventListener("turbo:load", function () {
  if (!alpineInitialized) {
    alpineInitialized = true;
    Alpine.start();
  }
});

document.addEventListener("DOMContentLoaded", function () {
  if (!alpineInitialized) {
    alpineInitialized = true;
    Alpine.start();
  }
});
